import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"
import RightArrowMedium from "../../images/icons/RightArrowMedium.svg"

const ItemWrapper = styled.article`
  margin-bottom: 7vh;
  .wrapper {
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 800px) {
      flex-direction: ${props =>
        props.orientation === "left" ? "row" : "row-reverse"};
      margin-bottom: 10rem;
    }
  }

  .wrapper:hover {
    .title,
    .subtitle {
      color: var(--yellow);
    }

    .hover-link,
    .hover-caption {
      display: block;
    }

    .background-image::before {
      background-blend-mode: multiply;
    }
  }

  .image-wrapper {
    width: 100%;
    height: 28vh;
    @media screen and (min-width: 800px) {
      width: 100%;
      height: 40vh;
    }

    .hover-link,
    .hover-caption {
      color: var(--white);
      display: none;
      font-family: "Galano Grotesque Medium", sans-serif;
      font-size: 18px;
      @media screen and (min-width: 800px) {
        font-size: 24px;
      }
    }

    .background-image {
      position: relative;

      .hover-caption {
        position: absolute;
        left: 1rem;
        right: 1rem;
        top: 1rem;
        @media screen and (min-width: 800px) {
          left: 4rem;
          right: 4rem;
          top: 4rem;
        }
      }
      .hover-link {
        position: absolute;
        bottom: 1rem;
        left: 1rem;
        right: 1rem;
        @media screen and (min-width: 800px) {
          bottom: 2rem;
          left: 4rem;
          right: 4rem;
        }
      }
    }
  }

  .title-wrapper {
    display: flex;
    flex-direction: column;
    padding: 1rem 0 0;

    @media screen and (min-width: 800px) {
      justify-content: center;
      padding: ${props =>
        props.orientation === "left" ? "0 0 0 4rem" : "0 4rem 0 0"};
      width: 100%;
    }

    .title,
    .subtitle {
      color: var(--navy);
      font-family: "DIN Next LT Pro Bold", sans-serif;
      font-size: 24px;
      line-height: 24px;
      margin: 0;

      @media screen and (min-width: 800px) {
        font-size: 40px;
        line-height: 40px;
      }
    }

    .subtitle {
      display: ${props => (props.subtitleExists === true ? "block" : "none")};
    }
  }
`

const FeaturedWork = ({
  url,
  title,
  subtitle,
  tagline,
  image,
  orientation,
  titleColour,
}) => {
  const subtitleExists = title === subtitle ? false : true

  return (
    <ItemWrapper
      orientation={orientation}
      titleColour={titleColour}
      subtitleExists={subtitleExists}
    >
      <div className="wrapper">
        <div className="image-wrapper">
          <Link className="image-cta" to={`/work/${url}`}>
            <BackgroundImage
              backgroundColor={`var(--navy)`}
              className="background-image"
              fluid={image}
              style={{ height: `100%` }}
            >
              <p className="hover-caption">{tagline}</p>
              <p className="hover-link">
                View case study <RightArrowMedium />
              </p>
            </BackgroundImage>
          </Link>
        </div>
        <div className="title-wrapper">
          <Link to={`/work/${url}`}>
            <h2 className="title">{title}</h2>
            <h2 className="subtitle">{subtitle}</h2>
          </Link>
        </div>
      </div>
    </ItemWrapper>
  )
}

export default FeaturedWork
