import React from "react"
import styled from "styled-components"
import Grid from "../Layout/Grid"
import BrandImage from "../../images/tb-animation-cropped.svg"

const BrandWrapper = styled.section`
  background: var(--navy);
  padding-top: 50px;

  @media screen and (min-width: 800px) {
    background: unset;
    padding-top: 74px;
  }

  .brand-wrapper {
    background: var(--navy);
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 800px) {
      display: grid;
      grid-template-columns: 2fr 2fr;
      margin: 0 0 5rem 0;
    }

    .caption-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;

      @media screen and (min-width: 800px) {
        padding: 1rem;
      }

      @media screen and (min-width: 800px) {
        padding: 0 4rem 0 4rem;
      }
      .brand-title {
        color: var(--blue);
        font-family: var(--heading);
        font-size: 30px;
        line-height: 36px;

        @media screen and (min-width: 800px) {
          font-size: 40px;
          line-height: 55px;
        }
      }

      .brand-message {
        color: var(--white);
        display: none;
        font-size: 21px;

        @media screen and (min-width: 800px) {
          display: block;
          line-height: 32px;
        }
      }
    }

    .animation-wrapper {
      display: flex;
      height: 45vh;
      justify-content: flex-end;
      overflow: hidden;
      position: relative;

      @media screen and (min-width: 800px) {
        height: 80vh;
      }

      .animation {
        height: auto;
        width: 100%;
        position: absolute;
        top: 0;
        right: 0;

        @media screen and (min-width: 800px) {
          height: 100%;
          width: 100%;
        }
      }
    }
  }
`

const FP02Brand = () => {
  return (
    <BrandWrapper id="brand">
      <Grid>
        <div className="brand-wrapper">
          <div className="caption-wrapper">
            <h1 className="brand-title">
              We help retail and hospitality businesses build brands their
              customers want.
            </h1>
            <p className="brand-message">
              We’re an empathy-based design agency. Working as an extension of
              your team, we’ll help you find the position you can uniquely own –
              then thoughtfully bring it to life.
            </p>
          </div>
          <div className="animation-wrapper">
            <BrandImage className="animation" />
          </div>
        </div>
      </Grid>
    </BrandWrapper>
  )
}

export default FP02Brand
