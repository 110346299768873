import React from "react"
import Layout from "../components/Layout/Layout"
import Head from "../components/Layout/Head"
import FP01Hero from "../components/FrontPage/FP01Hero"
import FP02Brand from "../components/FrontPage/FP02Brand"
import FP03Work from "../components/FrontPage/FP03Work"

const IndexPage = () => {
  return (
    <Layout>
      <Head title="Empathy Led Design" />
      <FP01Hero />
      <FP02Brand />
      <FP03Work />
    </Layout>
  )
}

export default IndexPage
