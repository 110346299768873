import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Grid from "../Layout/Grid"
import DownArrowSmall from "../../images/icons/DownArrowSmall.svg"

const HeroWrapper = styled.section`
  .jumbotron-wrapper {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    h1 {
      font-size: 55px;
      line-height: 55px;
      @media screen and (min-width: 800px) {
        font-size: 100px;
        line-height: 100px;
      }
    }

    /* @keyframes slideLeftToRight {
      from {
        left: -300vw;
      }
      to {
        left: 16px;
        right: 16px;
      }
    }

    @media screen and (min-width: 800px) {
      @keyframes slideLeftToRight {
        from {
          left: -300vw;
        }
        to {
          left: calc(1 / 24 * 100vw);
          right: calc(1 / 24 * 100vw);
        }
      }
    } */

    @keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
    .hero-title {
      animation-name: fadeIn;
      animation-duration: 2s;
      animation-delay: 1s;
      animation-fill-mode: forwards;
      color: var(--navy);
      margin: 0;
      opacity: 0;
      width: minmax(343px, 10fr);

      @media screen and (min-width: 800px) {
        width: calc(22 / 24 * 100vw);
      }
    }

    .hero-subtitle {
      color: rgba(34, 42, 53, 0.7);
      animation-name: fadeIn;
      animation-duration: 2s;
      animation-delay: 2.5s;
      animation-fill-mode: forwards;
      margin: 0;
      opacity: 0;
      padding: 3rem 0 0;
      width: minmax(343px, calc(10 / 12 * 100vw));

      @media screen and (min-width: 800px) {
        padding: 5rem 0;
        width: calc(22 / 24 * 100vw);
      }
    }

    .scroll {
      display: flex;
      justify-content: center;
      padding: 3rem 0;

      .arrow {
        animation-name: fadeIn;
        animation-duration: 1s;
        animation-delay: 3s;
        animation-fill-mode: forwards;
        opacity: 0;
        width: 30px;
        height: auto;
      }
    }
  }
`

const FP01Hero = () => {
  return (
    <HeroWrapper>
      <Grid>
        <div className="jumbotron-wrapper">
          <h1 className="hero-title">
            Great design isn't just about moving sales.
          </h1>
          <h1 className="hero-subtitle">It's about moving people.</h1>
          <div className="scroll">
            <Link to="/#brand">
              <DownArrowSmall className="arrow" />
            </Link>
          </div>
        </div>
      </Grid>
    </HeroWrapper>
  )
}

export default FP01Hero
